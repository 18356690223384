.custom-slider {
  height: 36px;

  @media (max-width: 768px) {
    height: 48px;
  }
}

.custom-slider .rc-slider-handle.rc-slider-handle-dragging {
  box-shadow: rgba(39, 139, 159, 0.16) 0 0 0 8px !important;

}