.custom-date-picker {
  padding: 0 14px;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
}

.custom-date-picker
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    border: none;
    background-color: transparent;
  }

  .react-datepicker__day-name {
    margin: 1px 0;
    padding: 5px 6px;
    font-size: 10px;
    font-weight: 600;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    line-height: 12px;
    color: #5E6062;
    text-transform: uppercase;
  }

  .react-datepicker__day {
    margin: 1px 0;
    padding: 5px 6px;
    font-size: 12px;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    line-height: 18px;
    color: #252728;
  }

  .react-datepicker__day--today:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #278B9F;
  }

  .react-datepicker__day--in-range {
    border-radius: 0;
    color: white;
    background-color: #278B9F;
  }

  .react-datepicker__day--in-range:hover {
    border-radius: 0;
    background-color: #278B9F;
  }

  .react-datepicker__day--range-start {
    border-radius: 8px 0 0 8px;
  }

  .react-datepicker__day--range-start:hover {
    border-radius: 8px 0 0 8px;
  }

  .react-datepicker__day--range-end {
    border-radius: 0 8px 8px 0;
  }

  .react-datepicker__day--range-end:hover {
    border-radius: 0 8px 8px 0;
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    color: white;
    background-color: #278B9F;
  }

  .react-datepicker__day--in-selecting-range {
    border-radius: 0;
  }

  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
    border-radius: 0.3rem;
  }

  .react-datepicker__day--selecting-range-start {
    border-radius: 8px 0 0 8px;
  }

  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
    border-radius: 0 8px 8px 0;
  }

  .react-datepicker__day--keyboard-selected {
    color: white;
    background-color: transparent;
  }

  .react-datepicker__day--keyboard-selected:hover {
    background-color: #f0f0f0;
  }

  .react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected {
    background-color: #278B9F;
  }

  .react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected:hover {
    background-color: #278B9F;
  }

  .react-datepicker__day--disabled {
    color: #BABBBC;
  }

  .react-datepicker__day--today {
    font-weight: 400;
  }
